// @ts-nocheck
import React from 'react';
import SpotifyPlayer from '../../../players/SpotifyPlayer';

interface ISpotifyPlayerMediaProps {
  className?: string;
  url: string;
}

const SpotifyPlayerMedia = ({
  className = '',
  url
}: ISpotifyPlayerMediaProps) => {
  return (
    <div className={`w-full overflow-hidden ${className}`}>
      <SpotifyPlayer url={url} />
    </div>
  );
};

export default SpotifyPlayerMedia;
