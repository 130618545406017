import React from "react";

// Components
import YoutubePlayerMedia from "./media/YoutubePlayerMedia";
import TwitchPlayerMedia from "./media/TwitchPlayerMedia";
import SpotifyPlayerMedia from "./media/SpotifyPlayerMedia";
import TikTokPlayerMedia from "./media/TikTokPlayerMedia";
import FacebookPlayerMedia from "./media/FacebookPlayerMedia";
import TwitterPlayerMedia from "./media/TwitterPlayerMedia";

interface IMediaBlockProps {
    className?: string;
    platform: string;
    url: string;
}

const MediaBlock = ({ className = "", platform, url }: IMediaBlockProps) => {

    if (platform === "youtube") {
        return (
            <YoutubePlayerMedia
                className={className}
                url={url}
            />
        )
    }

    else if (platform === "twitch_live") {
        return (
            <TwitchPlayerMedia
                className={className}
                url={url}
            />
        )
    }

    else if (platform === "spotify") {
        return (
            <SpotifyPlayerMedia
                className={className}
                url={url}
            />
        )
    }

    else if (platform === "tiktok") {
        return (
            <TikTokPlayerMedia
                className={className}
                url={url}
            />
        )
    }

    else if (platform === "fbgaming") {
        return (
            <FacebookPlayerMedia
                className={className}
                url={url}
            />
        )
    }

    else if (platform === "twitter") {
        return (
            <TwitterPlayerMedia
                className={className}
                url={url}
            />
        )
    }

    return null
};

export default MediaBlock;