// @ts-nocheck
import React from 'react';
import ReactPlayer from 'react-player/facebook';

interface IFacebookPlayerMediaProps {
  className?: string;
  url: string;
}

const FacebookPlayerMedia = ({
  className = '',
  url
}: IFacebookPlayerMediaProps) => {
  if (!ReactPlayer.canPlay(url)) return null;

  return (
    <div className={`w-full overflow-hidden rounded-md bg-black ${className}`}>
      <ReactPlayer url={url} width="100%" height="100%" />
    </div>
  );
};

export default FacebookPlayerMedia;
