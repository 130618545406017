// @ts-nocheck
import React from 'react';
import ReactPlayer from 'react-player/twitch';

interface ITwitchPlayerMediaProps {
  className?: string;
  url: string;
}

const TwitchPlayerMedia = ({
  className = '',
  url
}: ITwitchPlayerMediaProps) => {
  if (!ReactPlayer.canPlay(url)) return null;

  return (
    <div className={`w-full overflow-hidden rounded-md ${className}`}>
      <ReactPlayer url={url} width="100%" height="200px" controls={true} />
    </div>
  );
};

export default TwitchPlayerMedia;
