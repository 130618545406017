import TikTokPlayer from 'components/players/TikTokPlayer';

interface ITikTokPlayerMediaProps {
  className?: string;
  url: string;
}

const TikTokPlayerMedia = ({
  className = '',
  url
}: ITikTokPlayerMediaProps) => {
  return (
    <div className={className}>
      <TikTokPlayer className="mx-auto" size="xl" url={url} />
    </div>
  );
};

export default TikTokPlayerMedia;
