// @ts-nocheck
import React from 'react';
import { spotify_RegExp } from 'helpers/customRegExp';

interface ISpotifyPlayer_V2Props {
  url: string;
}

// https://open.spotify.com/track/4ORXSxr4tV5H6gH5KHAiAh?si=566c3d305db64a7b
// https://open.spotify.com/episode/0ShcusgcqmGkbO7Cx9idEY?si=29ff631ddcb54101
// https://open.spotify.com/playlist/0i7thbl6HYSH5JkoSurVsO?si=a0acb1f926cb48c0

const canPlay = (url: string): boolean => spotify_RegExp.test(url);

const SpotifyPlayer_V2 = ({ url }: ISpotifyPlayer_V2Props) => {
  if (!canPlay(url)) return null;

  const splitUrl = url.split('/');
  const splitId = splitUrl[4].split('?');
  const audioId = splitId[0];

  return (
    <iframe
      src={`https://open.spotify.com/embed/${
        url.includes('track')
          ? 'track'
          : url.includes('show')
          ? 'show'
          : url.includes('playlist')
          ? 'playlist'
          : 'episode'
      }/${audioId}?utm_source=generator`}
      width="100%"
      height={
        url.includes('track')
          ? '80px'
          : url.includes('playlist')
          ? '230px'
          : '152px'
      }
      style={{ borderRadius: '6px' }}
    ></iframe>
  );
};

SpotifyPlayer_V2.canPlay = (url: string): boolean => canPlay(url);

export default SpotifyPlayer_V2;
