import { createClient } from '@supabase/supabase-js';
import { AxiosApi } from 'requests/AxiosBase';
import { Audio } from 'types/monetization';
import { v4 as uuidv4 } from 'uuid';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
);

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    console.log(error.message);
    throw error;
  }

  return data || [];
};

export const updateUserName = async (user, name) => {
  await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id);
};

export const updateUser = async (user, data: any) => {
  const { error } = await supabase.from('users').update(data).eq('id', user.id);

  if (error) {
    console.log(error.message);
    throw error;
  }

  return { error: null };
};

export const insertTipRecord = async (payment) => {
  const metadata = payment.metadata;

  const tipData = {
    creator_id: metadata.creator_id,
    fan_id: metadata.fan_id ? metadata.fan_id : null,
    amount: Number(metadata.amount),
    currency: metadata.currency,
    name: metadata.name,
    message: metadata.message,
    gif: metadata.gif,
    type: metadata.type,
    connect_id: metadata.connect_id,
    createdAt: new Date().toISOString()
  };

  const { error } = await supabase.from('donations').insert([tipData]);
  if (error) throw error;
  console.log(`Tip inserted: ${payment.id}`);

  console.log('tip', payment);

  if (metadata.send_email === 'true') {
    await AxiosApi.post('/email/donation-notification', {
      creator_id: metadata.creator_id,
      fan_id: metadata.fan_id,
      amount: Number(metadata.amount)
    });
    console.log('Donation Email sended');
  }
};

export const insertGreetingRecord = async (payment) => {
  const metadata = payment.metadata;

  const greetingData = {
    creator_id: metadata.creator_id,
    fan_id: metadata.fan_id ? metadata.fan_id : null,
    amount: Number(metadata.amount),
    currency: metadata.currency,
    name: metadata.name,
    instructions: metadata.instructions,
    email: metadata.email,
    type: metadata.type,
    connect_id: metadata.connect_id,
    createdAt: new Date().toISOString(),
    isGift: metadata.isGift
  };

  const { error } = await supabase.from('greetings').insert([greetingData]);
  if (error) throw error;
  console.log(`Greeting inserted: ${payment.id}`);

  console.log('greeting', payment);

  if (metadata.send_email === 'true') {
    console.log('Sending email');
    await AxiosApi.post('/email/greeting-notification', {
      creator_id: metadata.creator_id,
      fan_id: metadata.fan_id
    });
    console.log('Greeting Email sended');
  }
};

export const insertQuestionRecord = async (payment) => {
  const metadata = payment.metadata;

  const questionData = {
    creator_id: metadata.creator_id,
    fan_id: metadata.fan_id ? metadata.fan_id : null,
    amount: Number(metadata.amount),
    currency: metadata.currency,
    name: metadata.name,
    question: metadata.question,
    email: metadata.email,
    type: metadata.type,
    connect_id: metadata.connect_id,
    createdAt: new Date().toISOString(),
    isGift: metadata.isGift
  };

  const { error } = await supabase.from('questions').insert([questionData]);
  if (error) throw error;
  console.log(`Question inserted: ${payment.id}`);

  console.log('Question', payment);

  if (metadata.send_email === 'true') {
    await AxiosApi.post('/email/question-notification', {
      creator_id: metadata.creator_id,
      fan_id: metadata.fan_id
    });
    console.log('Question Email sended');
  }
};

export const insertAudioRecord = async (payment) => {
  const metadata = payment.metadata;

  const audio: Audio = {
    amount: Number(metadata.amount),
    comments: metadata.comments,
    creation_date: new Date(),
    creator_account_id: metadata.creator_id,
    currency: metadata.currency,
    email: metadata.email,
    fan_id: metadata.fan_id ? metadata.fan_id : null,
    name: metadata.name,
    rejected: false,
    status: 'pending',
    type: 'audio',
    uuid: uuidv4()
  };

  const { error, data: result } = await supabase
    .from<Audio>('audios')
    .insert([audio]);

  console.log({ error, result });

  if (error) throw error;

  if (metadata.send_email === true) {
    await AxiosApi.post('/email/audio-notification', {
      creator_id: metadata.creator_id,
      fan_id: metadata.fan_id
    });
  }
};
