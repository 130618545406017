import axios from 'axios';
import { useRouter } from 'next/router';
import { MonetizationType } from 'types/monetization';
import { Mixpanel } from 'utils/mixpanel';

interface IMonetizationBlockProps {
  blockId: string;
  className?: string;
  creatorUsername: string;
  customButton?: string;
  fanUsername: string;
  feature: keyof typeof MonetizationType;
  isPreviewMode?: boolean;
  title: string;
  userId: string;
}

const MonetizationBlock = ({
  blockId,
  className = '',
  creatorUsername,
  customButton,
  fanUsername,
  feature,
  isPreviewMode,
  title,
  userId
}: IMonetizationBlockProps) => {
  const router = useRouter();

  const getFeatureQueryName = (feat: keyof typeof MonetizationType) => {
    switch (feat) {
      case 'gifts':
        return 'donate';
      case 'questions':
        return 'ask';
      case 'greetings':
        return 'greet';
      case 'audio':
        return 'record';
      default:
        return router.replace(`/${creatorUsername}`);
    }
  };

  const handleOnClick = () => {
    try {
      isPreviewMode === false &&
        axios({
          method: 'POST',
          url: 'api/user/analytics/block/click',
          data: {
            blockId,
            creator_id: userId
          }
        });

      isPreviewMode === false &&
        Mixpanel.track('Click on Monetization Block', {
          title,
          feature,
          creatorUsername: creatorUsername,
          fanUsername: fanUsername
        });

      router.push(`/${creatorUsername}/${getFeatureQueryName(feature)}`);
    } catch (e) {
      console.log(e);
      console.log('Plausible blocked by the user');
    }
  };

  return (
    <button
      className={`block w-full rounded p-3.5 text-center text-lg font-semibold min-h-[56px] ${className} ${
        customButton
          ? `bg-${customButton}-200 hover:bg-${customButton}-400 hover:text-white active:text-white active:bg-${customButton}-600 text-${customButton}-800 active:bg-${customButton}-800`
          : 'bg-primary-200 text-primary-800 hover:bg-primary-400 hover:text-white active:bg-primary-600  active:text-white'
      }`}
      onClick={handleOnClick}
    >
      {title}
    </button>
  );
};

export default MonetizationBlock;
