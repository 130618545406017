const solanaProvider = () => {
    if (typeof window === "undefined") return false

    const theWindow: any = window;

    if (!theWindow.solana || !theWindow.solana.isPhantom) return false

    return theWindow.solana
}

export default solanaProvider;