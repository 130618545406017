import { AxiosApi } from '../AxiosBase';
import { getCookie } from 'helpers/handleCookies';

interface IAxiosCaptureOrder {
  order_token: string;
}

interface IAxiosSwitchSubscription {
  type: 'ACTIVATE' | 'SUSPEND';
  subscription_id: string;
}

export const AxiosCreatePlan = async () => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logeado' };

  try {
    const { data } = await AxiosApi({
      method: 'POST',
      url: '/paypal/create-plan',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') return { error: data.message };

    return { data: data.data };
  } catch (e) {
    console.log(e);
    console.log('AxiosCreatePlan() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosCaptureOrder = async (payload: IAxiosCaptureOrder) => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logeado' };

  try {
    const { data } = await AxiosApi.post(
      '/paypal/subscription/capture-order',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return { success: 'PRO' };
  } catch (e) {
    console.log(e);
    console.log('AxiosCaptureOrder() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosValidateSubscription = async (creator_id: number) => {
  const userToken = getCookie('token');

  if (!userToken) return false;

  try {
    const { data } = await AxiosApi.get(
      `/paypal/validate-subscription/${creator_id}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    return data.is_subscribed;
  } catch (e) {
    console.log(e);
    console.log('AxiosValidateSubscription() Error');
    return false;
  }
};

export const AxiosSwitchSubscription = async (
  payload: IAxiosSwitchSubscription
) => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logeado' };

  try {
    const { data } = await AxiosApi.post(
      '/paypal/switch-subscription',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return { success: 'PRO' };
  } catch (e) {
    console.log(e);
    console.log('AxiosSwitchSubscription()');
    return { error: 'Error al conectarse con el servidor' };
  }
};
