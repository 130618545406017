import React from 'react';
import axios from 'axios';

import { Block } from 'types/accounts';

// Helpers
import { classNames } from 'helpers/classNames';

interface IDividerBlockProps {
  className?: string;
  userId: string;
  block: Block;
  customColor?: string;
  hasCoverImage?: boolean;
}

const DividerBlock = (
  props: IDividerBlockProps = {
    className: '',
    block: undefined,
    userId: undefined,
    customColor: undefined
  }
) => {
  const { userId, block, customColor, hasCoverImage } = props;

  return (
    <div
      className={`flex flex-row items-stretch gap-4 ${
        hasCoverImage
          ? 'text-white'
          : customColor
          ? `text-${customColor}-700`
          : 'text-primary-700'
      }`}
    >
      {block.title !== '' && (
        <a className="shrink-0 text-base font-medium leading-6 ">
          {block.title}
        </a>
      )}
      <a
        className={`h-[1px] w-full shrink self-center rounded-xl  ${
          hasCoverImage
            ? 'bg-white'
            : customColor
            ? `bg-${customColor}-700`
            : 'bg-primary-700'
        } `}
      />
    </div>
  );
};

export default DividerBlock;
