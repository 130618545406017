//Libraries
import { useEffect, useState } from 'react';

// Custom functions
import { getNFTsfromAddress, toPublicKey } from 'requests/local_next/web3';

// Helpers
import { classNames } from 'helpers/classNames';

// Types
import { Nft } from '@metaplex-foundation/js';

// Components
import SolanaNFTItem from './SolanaNFTItem';

interface SolanaNFTs {
  phantomAddress: string;
  hasCoverImage?: boolean;
  customButton?: string;
}

const SolanaNFTs: React.FC<SolanaNFTs> = ({
  phantomAddress,
  hasCoverImage,
  customButton
}) => {
  const [nftList, setNFTList] = useState<Array<Nft>>([]);

  useEffect(() => {
    const asyncGetNFTs = async () => {
      const walletPubKey = toPublicKey(phantomAddress);
      const newNftList = await getNFTsfromAddress(walletPubKey);

      if (newNftList === null) {
        setNFTList([]);
        return;
      }
      setNFTList(newNftList);
    };
    asyncGetNFTs();
  }, []);

  if (nftList.length == 0) {
    return null;
  }

  return (
    <div className="mb-4">
      <h2
        className={classNames(
          hasCoverImage
            ? 'text-white'
            : customButton
            ? `text-${customButton}-700`
            : 'text-primary-700',
          'mb-4 text-lg font-bold  xl:text-2xl'
        )}
      >
        Mis NFTs
      </h2>
      <div className="flex justify-center">
        <ul
          className="mx-3 flex gap-3 overflow-x-auto pb-2 first:ml-0 "
          // onScroll={scrollCheck}
        >
          {nftList.map((nft, index) => {
            return (
              <SolanaNFTItem
                key={index}
                nft={nft}
                hasCoverImage={hasCoverImage}
                customButton={customButton}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SolanaNFTs;
