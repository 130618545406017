type IconProps = {
  className?: string;
};

const CopyRegularIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="120"
      height="26"
      viewBox="0 0 120 26"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40471 14.147C5.40471 12.019 6.66712 10.758 8.8369 10.758C11.0856 10.758 11.9535 12.2161 11.9535 14.147V25.102H17.3187V14.147C17.3187 12.019 18.5812 10.758 20.7509 10.758C22.9996 10.758 23.907 12.2161 23.907 14.147V25.102H29.2722V12.6101C29.2722 8.59064 27.5364 5.91099 23.473 5.91099C20.2775 5.91099 18.1866 7.40844 17.3582 11.7826H17.2793C17.082 8.23598 15.3462 5.91099 11.559 5.91099C8.56074 5.91099 6.31207 7.36904 5.44416 11.6644H5.40471V6.42328H0V25.102H5.40471V14.147Z"
        fill="#D0D7DC"
      />
      <path
        d="M36.6296 4.45295V0.945758H31.0277V4.45295H36.6296ZM36.5507 25.102V6.42328H31.146V25.102H36.5507Z"
        fill="#D0D7DC"
      />
      <path
        d="M46.4043 25.6537C52.0063 25.6537 54.4522 23.3681 54.4522 19.7033C54.4522 16.9054 52.9136 14.8957 47.5484 13.8317C43.8795 13.1618 43.4061 12.4525 43.4061 11.4279C43.4061 10.4033 44.274 9.77284 45.852 9.77284C47.7062 9.77284 48.6135 10.7186 49.0475 12.7283L54.1761 11.5855C53.466 8.11776 51.2567 5.91099 45.8915 5.91099C40.6446 5.91099 38.0803 8.11776 38.0803 11.6644C38.0803 14.7381 39.8555 16.5114 45.1025 17.6148C48.4557 18.2847 49.1659 18.8758 49.1659 19.9003C49.1264 21.0431 48.2585 21.6736 46.5227 21.6736C44.2346 21.6736 43.1299 20.452 42.6171 18.3635L37.6069 19.3486C38.1592 22.777 40.0923 25.6537 46.4043 25.6537Z"
        fill="#D0D7DC"
      />
      <path
        d="M62.2297 6.10803C62.2297 4.57117 62.9793 3.90125 64.3995 3.90125C64.9912 3.90125 65.583 3.94066 66.2142 4.05888L66.6087 0.275845C65.8592 0.118221 65.0307 0 63.6105 0C58.9948 0 56.8645 2.56143 56.8645 6.14743V6.42328H54.3396V10.9156H56.8645V25.102H62.2297V10.9156H66.2142V6.42328H62.2297V6.10803Z"
        fill="#D0D7DC"
      />
      <path
        d="M74.0441 21.1614C71.9927 21.1614 71.2826 20.255 71.2826 19.1122C71.2826 18.0876 72.2294 17.3783 73.8074 17.3783H77.8314V17.93C77.8314 19.8215 76.3322 21.1614 74.0441 21.1614ZM77.8314 25.102H83.1966V13.5165C83.1966 8.74827 80.8296 5.98981 75.1487 5.98981C69.3495 5.98981 67.0614 8.78767 66.588 12.6889L71.6376 13.3982C71.9927 11.2309 73.1367 10.4428 74.912 10.4428C76.9634 10.4428 77.8314 11.5461 77.8314 13.5559V14.344H72.979C68.0477 14.344 66.0357 16.669 66.0357 20.058C66.0357 23.7228 68.4816 25.4961 71.7165 25.4961C74.8726 25.4961 76.924 23.7228 77.7525 20.5308H77.8314V25.102Z"
        fill="#D0D7DC"
      />
      <path
        d="M90.383 14.147C90.383 12.019 91.6454 10.758 93.8152 10.758C96.0638 10.758 96.9317 12.2161 96.9317 14.147V25.102H102.297V12.6101C102.297 8.59064 100.601 5.91099 96.5372 5.91099C93.539 5.91099 91.2903 7.36904 90.4224 11.6644H90.383V6.42328H84.9783V25.102H90.383V14.147Z"
        fill="#D0D7DC"
      />
      <path
        d="M111.952 25.6537C117.554 25.6537 120 23.3681 120 19.7033C120 16.9054 118.461 14.8957 113.096 13.8317C109.427 13.1618 108.954 12.4525 108.954 11.4279C108.954 10.4033 109.822 9.77284 111.4 9.77284C113.254 9.77284 114.161 10.7186 114.595 12.7283L119.724 11.5855C119.014 8.11776 116.805 5.91099 111.439 5.91099C106.192 5.91099 103.628 8.11776 103.628 11.6644C103.628 14.7381 105.403 16.5114 110.65 17.6148C114.004 18.2847 114.714 18.8758 114.714 19.9003C114.674 21.0431 113.806 21.6736 112.07 21.6736C109.782 21.6736 108.678 20.452 108.165 18.3635L103.155 19.3486C103.707 22.777 105.64 25.6537 111.952 25.6537Z"
        fill="#D0D7DC"
      />
    </svg>
  );
};

export default CopyRegularIcon;
