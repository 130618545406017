import React from 'react';

// Components icons
import {
  GrInstagram,
  GrTwitter,
  GrYoutube,
  GrFacebook,
  GrSpotify,
  GrSnapchat
} from 'react-icons/gr';
import { FaTwitch, FaTiktok } from 'react-icons/fa';
import OpenseaIcon from 'components/tailwind/icons/OpenseaIcon';
import ClubhouseIcon from 'components/tailwind/icons/ClubhouseIcon';

interface ISocialIconProps {
  icon: string;
  customColor?: string;
}

const SocialIcon = ({ icon, customColor }: ISocialIconProps) => {
  const classNameIcon: string = `h-5 w-5 mr-2 ${
    customColor
      ? customColor === 'white'
        ? 'fill-white'
        : `fill-${customColor}-500`
      : 'fill-primary'
  }`;

  const iconLower = icon.toLowerCase();

  if (iconLower === 'instagram')
    return <GrInstagram className={classNameIcon} />;
  else if (iconLower === 'twitter')
    return <GrTwitter className={classNameIcon} />;
  else if (iconLower === 'youtube')
    return <GrYoutube className={classNameIcon} />;
  else if (iconLower === 'facebook')
    return <GrFacebook className={classNameIcon} />;
  else if (iconLower === 'spotify')
    return <GrSpotify className={classNameIcon} />;
  else if (iconLower === 'tiktok')
    return <FaTiktok className={classNameIcon} />;
  else if (iconLower === 'snapchat')
    return <GrSnapchat className={classNameIcon} />;
  else if (iconLower === 'opensea')
    return <OpenseaIcon className={classNameIcon} />;
  else if (iconLower === 'clubhouse')
    return <ClubhouseIcon className={classNameIcon} />;

  return <FaTwitch className={classNameIcon} />;
};

export default SocialIcon;
