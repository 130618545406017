import React from 'react';

// Components
import LinkBlock from './blocks/LinkBlock';
import MonetizationBlock from './blocks/MonetizationBlock';
import MediaBlock from './blocks/MediaBlock';
import SolanaNFTBlock from './blocks/SolanaNFTBlock';
import DigitalProductBlock from './blocks/DigitalProductBlock';
import DividerBlock from './blocks/DividerBlock';
import ShopifyProductBlock from './blocks/ShopifyProductBlock'

// Types
import { IBlocks } from "types/accounts/internalOptions"

interface IUserBlocksProps {
  blocks: any[];
  creatorUsername: string;
  customButton?: string;
  donationEnabled: boolean;
  fanUsername: string;
  hasCoverImage?: boolean;
  isPreviewMode?: boolean;
  userId: string;
  internal_options_blocks: IBlocks
}

const UserBlocks = ({
  blocks,
  creatorUsername,
  customButton,
  fanUsername,
  hasCoverImage,
  isPreviewMode,
  userId,
  internal_options_blocks
}: IUserBlocksProps) => {
  return (
    <div className="space-y-3">
      {blocks?.length > 0 &&
        blocks?.map((block, index) => {
          if (!block.visible) return null;

          if (block.type === 'link') {
            return (
              <LinkBlock
                blockId={block.id}
                className={`transition-all duration-200 hover:scale-[1.005] `}
                customButton={customButton}
                isPreviewMode={isPreviewMode}
                key={index}
                title={block.title}
                url={block.url}
                userId={userId}
                username={creatorUsername}
                og_image={block.og_image}
              />
            );
          } else if (block.type === 'media') {
            return (
              <MediaBlock
                className=""
                key={index}
                platform={block.platform}
                url={block.url}
              />
            );
          } else if (block.type === 'monetization') {
            if (
              (block.feature === "greetings" && internal_options_blocks?.greeting === false) ||
              (block.feature === "questions" && internal_options_blocks?.question === false) ||
              (block.feature === "audio" && internal_options_blocks?.audio === false)
            ) return null

            return (
              <MonetizationBlock
                blockId={block.id}
                className="transition-all duration-200 hover:scale-[1.005]"
                creatorUsername={creatorUsername}
                customButton={customButton}
                fanUsername={fanUsername}
                feature={block.feature}
                isPreviewMode={isPreviewMode}
                key={index}
                title={block.title}
                userId={userId}
              />
            );
          } else if (block.type === 'nftShowcase') {
            if (internal_options_blocks?.nft_collection_hub === false) return null
            return (
              <SolanaNFTBlock
                address={block.address}
                customButton={customButton}
                key={index}
                nftBlockData={block.nftBlockData}
                title={block.title}
              />
            );
          } else if (block.type === 'digitalProduct') {
            if (internal_options_blocks?.digital_product === false) return null

            return (
              <DigitalProductBlock
                block={block}
                className="transition-all duration-200 hover:scale-[1.005]"
                customColor={customButton}
                isPreviewMode={isPreviewMode}
                key={index}
                userId={userId}
                username={creatorUsername}
              />
            );
          } else if (block.type === 'divider') {
            return (
              <DividerBlock
                block={block}
                className="transition-all duration-200 hover:scale-[1.005]"
                customColor={customButton}
                hasCoverImage={hasCoverImage}
                key={index}
                userId={userId}
              />
            );
          } else if (block.type === "shopify_product" || block.type === "gloset_product") {
            return (
              <ShopifyProductBlock
                type={block.type}
                url={block.url}
                {...block.data}
              />
            )
          }
        })}
    </div>
  );
};

export default UserBlocks;
