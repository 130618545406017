// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';

// Components
import Button from '../buttons/Button';
import { FaWallet } from 'react-icons/fa';

// Helpers
import { defaultToastOptions } from 'helpers/toastOptions';
import solanaProvider from 'helpers/solanaProvider';
import walletSession from 'helpers/walletSession';

// Requests
import {
  AxiosNonceUserProfile,
  AxiosVerifyNonce,
  AxiosDisconnectWalletProfile
} from 'requests/local_api/AxiosPhantom';

// Hooks
import { useUser } from 'hooks/useUser';

interface IWalletCardProps {
  className?: string;
  username: string;
  user_id: string;
  addr: string;
  fan_authenticated: boolean;
  connected: boolean;
  customColor?: string;
}

const WalletCard = ({
  className = '',
  username,
  user_id,
  addr: theAddr,
  fan_authenticated,
  connected,
  customColor
}: IWalletCardProps) => {
  const { user, status } = useUser();
  const walletAuth = useRef(walletSession(user_id));

  const [addr, setAddr] = useState({
    status: !fan_authenticated ? 0 : !connected ? 2 : 1,
    data: theAddr
  });

  useEffect(() => {
    const effectFunc = async () => {
      if (fan_authenticated) return;
      walletAuth.current.verify();

      if (walletAuth.current.closedSession()) {
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        return;
      }

      const session = walletAuth.current.getSession();

      if (!session) {
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        return;
      }

      setAddr((prev) => ({
        ...prev,
        status: 1,
        data: session.addr
      }));
    };

    effectFunc();
  }, []);

  const handleOnClick = async () => {
    if (addr.status === 0 || status === 0) return;
    const provider = solanaProvider();

    if (!provider)
      return toast.error('No tienes la extensión de phantom instalada :(');
    else if (addr.status === 1) {
      setAddr((prev) => ({
        ...prev,
        status: 3
      }));

      const { error } = await AxiosDisconnectWalletProfile({
        addr: addr.data,
        user_id
      });

      if (error && error !== 'Tu wallet ya no esta vinculada desde antes') {
        toast.error(error);
        return;
      }

      if (!fan_authenticated) {
        provider.disconnect();
        walletAuth.current.logout();
      }

      setAddr((prev) => ({
        ...prev,
        status: 2,
        data: ''
      }));

      toast.success(
        'Has desvinculado tu wallet en este perfil satisfactoriamente'
      );
      return;
    }

    setAddr((prev) => ({
      ...prev,
      status: 0
    }));

    if (status === 1) {
      if (!user.linked_accounts.phantom.addr) {
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        toast.error(
          'No tienes una cuenta de phantom vinculada a tu cuenta, para hacerlo debe ir a la pantalla de ajustes'
        );
        return;
      }

      const { error } = await AxiosNonceUserProfile({
        user_id,
        addr: user.linked_accounts.phantom.addr
      });

      if (error) {
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        toast.error(error, defaultToastOptions);
        return;
      }

      setAddr((prev) => ({
        ...prev,
        status: 1,
        data: user.linked_accounts.phantom.addr
      }));

      toast.success('Tu wallet a sido conectada a este perfil correctamente!');
      return;
    }

    try {
      const { publicKey } = await provider.connect();

      const { error, data } = await AxiosNonceUserProfile({
        addr: publicKey.toString(),
        user_id
      });

      if (error) {
        walletAuth.current.logout();
        toast.error(error, defaultToastOptions);
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        return;
      }

      const message = `${data} esto es para verificar que la wallet exista`;
      const encode = new TextEncoder().encode(message);
      const signedMessage = await provider.signMessage(encode, 'utf8');

      const { error: error_1 } = await AxiosVerifyNonce({
        message,
        addr: publicKey.toString(),
        signature: JSON.stringify(signedMessage.signature),
        user_id
      });

      if (error_1) {
        walletAuth.current.logout();
        toast.error(error_1);
        setAddr((prev) => ({
          ...prev,
          status: 2
        }));
        return;
      }

      walletAuth.current.loggin(publicKey.toString());

      setAddr((prev) => ({
        ...prev,
        status: 1,
        data: publicKey.toString()
      }));

      toast.success(
        'Tu wallet a sido vinculada a este perfil satisfactoriamente'
      );
    } catch (e) {
      console.log(e);
      console.log('handleOnClick() Error');
      setAddr((prev) => ({
        ...prev,
        status: 2
      }));
    }
  };

  return (
    <div className={className}>
      <div className={` ${solanaProvider() ? '' : 'mb-4'}`}>
        <div className="rounded-t border-x border-t border-gray-200 bg-white px-4 pb-3 pt-4">
          <h3 className="text-xl font-semibold text-gray-700">
            Únete a mi comunidad
          </h3>
          <h4 className="text-sm font-normal leading-5 text-gray-500">
            Conecta tu wallet de Solana para acceder a la comunidad de{' '}
            <span className="font-semibold text-gray-700">{username}</span>
          </h4>
        </div>

        <Button
          className={`${
            addr.status === 2 ? '' : 'bg-primary'
          } w-full rounded-t-none text-lg font-semibold transition-all duration-300`}
          text={`${
            addr.status === 1
              ? `Wallet conectada ${addr.data.slice(0, 4)}...${addr.data.slice(
                  -4
                )}`
              : addr.status === 2
              ? 'Conectar mi wallet'
              : addr.status === 3
              ? 'Desconectando...'
              : 'Conectando...'
          }`}
          icon={FaWallet}
          onClick={handleOnClick}
          disabled={addr.status === 0 || addr.status === 3}
          variant={
            customColor
              ? addr.status === 2
                ? 'customColorSecondary'
                : 'customColorPrimary'
              : addr.status === 2
              ? 'secondary'
              : 'primary'
          }
          customColor={customColor}
        />
      </div>

      {!solanaProvider() && (
        <h5 className="text-sm text-gray-700">
          ¿No tienes una wallet de Solana?{' '}
          <a className="text-black" href="#">
            Crea una aquí
          </a>
        </h5>
      )}
    </div>
  );
};

export default WalletCard;
