// Libraries
import { Connection, PublicKey } from '@solana/web3.js';
import { Metaplex } from '@metaplex-foundation/js';

// Types
import { ParsedAccountData } from '@solana/web3.js';

// API
import { AxiosGetUserFromAddress } from 'requests/local_api/AxiosPhantom';

const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC);
const metaplex = Metaplex.make(connection);

const getRawNFTsFromAddress = async (publicKey: PublicKey) => {
  const nfts = await metaplex.nfts().findAllByOwner(publicKey);

  return nfts;
};

const getNFTsfromAddress = async (publicKey: PublicKey) => {
  try {
    const nfts = await getRawNFTsFromAddress(publicKey);

    await Promise.all(
      nfts.map((nft) => {
        return nft.metadataTask.run().catch((error) => {
          console.log('getNFTsfromAddress() Error at NFT:', nft);
        });
      })
    ).catch((error) => {
      console.log(error);
    });

    return nfts;
  } catch (error) {
    console.log('getNFTsfromAddress() Error:', error);

    return null;
  }
};

const getNFTOwner = async (nftPubKey: PublicKey) => {
  const largestAccounts = await connection.getTokenLargestAccounts(nftPubKey);
  const largestAccountsInfo = await connection.getParsedAccountInfo(
    largestAccounts.value[0].address
  );
  const parsedAccountData = largestAccountsInfo.value.data as ParsedAccountData;
  const nftOwner: string = parsedAccountData.parsed.info.owner;

  const ownerData = await AxiosGetUserFromAddress({ address: nftOwner });

  if (ownerData.error) {
    return { address: nftOwner };
  } else {
    return {
      username: ownerData.user.username,
      address: nftOwner
    };
  }
};

const toPublicKey = (address: string) => {
  const pubKey = new PublicKey(address);

  return pubKey;
};

const getTokenMetadata = async (nftPubKey: PublicKey) => {
  const nft = await metaplex.nfts().findByMint(nftPubKey);

  return nft;
};

const prepareNFTBlock = async (nftAddress: string) => {
  try {
    const nftPubKey = toPublicKey(nftAddress);
    const tokenMetadata = await getTokenMetadata(nftPubKey);

    const uriData = tokenMetadata.metadataTask.getResult();

    const nftOwner = await getNFTOwner(nftPubKey);

    return { uriData, nftOwnerData: nftOwner };
  } catch (error) {
    return { error: 'Address inválido' };
  }
};

export type nftBlockData = Awaited<ReturnType<typeof prepareNFTBlock>>;

const comparePublicKeys = (a: PublicKey, b: PublicKey) => {
  return a.equals(b);
};

const verifyHolder = async (
  walletAddress: PublicKey,
  collectionPubKey: PublicKey
) => {
  try {
    const nfts = await getRawNFTsFromAddress(walletAddress);

    if (nfts.length === 0) {
      return false;
    }

    const isHolder = nfts.some((nft) => {
      if (nft.collection === null) {
        return false;
      }
      return comparePublicKeys(nft.collection.key, collectionPubKey);
    });

    return isHolder;
  } catch (error) {
    console.log('verifyHolder() Error:', error);
  }
};

export {
  comparePublicKeys,
  getNFTsfromAddress,
  getRawNFTsFromAddress,
  getTokenMetadata,
  prepareNFTBlock,
  toPublicKey,
  verifyHolder
};
