import axios from 'axios';
import { matchHttp } from 'helpers/matchHttp';
import { Mixpanel } from 'utils/mixpanel';

interface ILinkBlockProps {
  blockId: string;
  className?: string;
  customButton?: string;
  isPreviewMode?: boolean;
  title: string;
  url: string;
  userId: string;
  username: string;
  og_image?: string;
}

const LinkBlock = ({
  blockId,
  className = '',
  customButton,
  isPreviewMode,
  title,
  url,
  userId,
  username,
  og_image
}: ILinkBlockProps) => {
  const handleOnClick = async () => {
    try {
      const theWindow: any = window;

      isPreviewMode === false &&
        theWindow.plausible('Clicks', {
          props: {
            [userId.toString()]: title
          }
        });

      isPreviewMode === false &&
        axios({
          method: 'POST',
          url: 'api/user/analytics/block/click',
          data: {
            blockId,
            creator_id: userId
          }
        });

      isPreviewMode === false &&
        Mixpanel.track('Click on Link Block', {
          title,
          url,
          creatorUsername: username
        });
    } catch (e) {
      console.log(e);
      console.log('Plausible blocked by the user');
    }
  };

  return (
    <a
      className={`${og_image ? "flex" : "block"} min-h-[56px] rounded p-3.5 text-center text-lg font-semibold ${className} ${
        customButton
          ? `bg-${customButton}-200 hover:bg-${customButton}-400 hover:text-white active:text-white active:bg-${customButton}-600 text-${customButton}-800 active:bg-${customButton}-800`
          : 'bg-primary-200 text-primary-800 hover:bg-primary-400 hover:text-white active:bg-primary-600  active:text-white'
      }`}
      href={matchHttp(url)}
      target="_blank"
      onClick={handleOnClick}
    >
      {
        og_image ? (
          <>
            <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] mr-4">
              <img
                className="w-full h-full object-cover object-center rounded-lg"
                src={og_image}
                alt="link og image MisFans"
              />
            </div>

            <span>{title}</span>
          </>
        ) : title
      }
    </a>
  );
};

export default LinkBlock;
