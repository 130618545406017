const ITEM_NAME = "mf_phantom";

const walletSession = (user_id: number) => {

  const verify = () => {
    try {
      const parse = JSON.parse(localStorage.getItem(ITEM_NAME));

      if (!Array.isArray(parse)) throw `${ITEM_NAME} should be an array`

      const every = parse.every((value) => {
        return value.id && typeof value.id === "number" && value.addr && typeof value.addr === "string"
      });

      if (!every) throw "Nope"
    }

    catch(e) {
      localStorage.removeItem(ITEM_NAME);
    }
  }

  const closedSession = () => {
    try {
      const parse = JSON.parse(localStorage.getItem(ITEM_NAME));

      if (!Array.isArray(parse)) throw `${ITEM_NAME} should be an array`

      return !parse.some((value) => value.id === user_id);
    }

    catch(e) {
      localStorage.removeItem(ITEM_NAME);
      return true
    }
  }

  const loggin = (addr: string) => {
    try {
      const parse = JSON.parse(localStorage.getItem(ITEM_NAME));

      if (!Array.isArray(parse)) throw `${ITEM_NAME} should be an array`

      parse.push({ id: user_id, addr })

      localStorage.setItem(ITEM_NAME, JSON.stringify(parse))
    }

    catch(e) {
      localStorage.setItem(ITEM_NAME, JSON.stringify([{ id: user_id, addr }]))
    }
  }

  const logout = () => {
    try {
      const parse = JSON.parse(localStorage.getItem(ITEM_NAME));

      if (!Array.isArray(parse)) throw `${ITEM_NAME} should be an array`

      const filter = parse.filter((value) => value.id !== user_id);

      localStorage.setItem(ITEM_NAME, JSON.stringify(filter))
    }

    catch(e) {
      localStorage.removeItem(ITEM_NAME);
    }
  }

  const getSession = () => {
    try {
      const parse = JSON.parse(localStorage.getItem(ITEM_NAME));

      if (!Array.isArray(parse)) throw `${ITEM_NAME} should be an array`

      const find = parse.find((value: any) => value.id === user_id)

      return find
    }

    catch(e) {
      return undefined
    }
  }

  return {
    closedSession,
    loggin,
    logout,
    getSession,
    verify
  }
}

export default walletSession