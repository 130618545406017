import { useState } from 'react';

// Types
import { Nft } from '@metaplex-foundation/js';

// Helpers
import { classNames } from 'helpers/classNames';

interface ISolanaNFTItem {
  nft: Nft;
  hasCoverImage?: boolean;
  customButton?: string;
}

const SolanaNFTItem: React.FC<ISolanaNFTItem> = ({
  nft,
  hasCoverImage,
  customButton
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <a
      href={`https://solscan.io/token/${nft.mint}`}
      target="_blank"
      className="w-[140px]"
    >
      {!isLoaded && (
        <div className="h-[140px] w-[140px] animate-pulse rounded-lg bg-slate-200"></div>
      )}
      <div className={!isLoaded ? 'h-0' : 'h-[140px] w-[140px]'}>
        <img
          src={nft.metadataTask.isSuccessful() ? nft.metadata.image : ''}
          alt={nft.name}
          className="mx-auto max-h-full rounded-lg"
          onLoad={() => setIsLoaded(true)}
        />
      </div>
      <h3
        className={classNames(
          hasCoverImage
            ? 'text-white'
            : customButton
            ? `text-${customButton}-800`
            : 'text-primary-700',
          'mt-3 truncate text-sm font-semibold'
        )}
      >
        {nft.name}
      </h3>
    </a>
  );
};

export default SolanaNFTItem;
