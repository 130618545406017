interface ISolanaWhite {
  className: string;
}

const SolanaWhite: React.FC<ISolanaWhite> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="158"
      height="121"
      viewBox="0 0 158 121"
      fill="none"
      className={className}
    >
      <path
        d="M153.551 0H29L3.40386 26.9674C2.49681 27.923 3.17424 29.5 4.49182 29.5H129.5L154.648 2.52281C155.542 1.56376 154.862 0 153.551 0Z"
        fill="white"
      />
      <path
        d="M153.551 91.5H29L3.40386 118.467C2.49681 119.423 3.17424 121 4.49182 121H129.5L154.648 94.0228C155.542 93.0638 154.862 91.5 153.551 91.5Z"
        fill="white"
      />
      <path
        d="M129.5 45.5H3.62132C2.28496 45.5 1.61571 47.1157 2.56066 48.0607L29 74.5H154.423C155.753 74.5 156.425 72.8973 155.493 71.9486L129.5 45.5Z"
        fill="white"
      />
    </svg>
  );
};

export default SolanaWhite;
