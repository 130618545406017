// Libraries
import { ExternalLinkIcon } from '@heroicons/react/outline';

// Types
import { nftBlockData } from 'requests/local_next/web3';

// Helpers
import { formatPublicKey } from 'helpers/formatData';

type SolanaNFTBlockProps = {
  title: string;
  nftBlockData: nftBlockData;
  address: string;
  customButton?: string;
};

const SolanaNFTBlock: React.FC<SolanaNFTBlockProps> = ({
  title,
  address,
  nftBlockData,
  customButton
}) => {
  if (nftBlockData) {
    return (
      <div className="mb-5 rounded-xl bg-white md:mx-auto md:w-80">
        <img
          src={nftBlockData?.uriData?.image}
          alt={nftBlockData?.uriData?.name}
          className="rounded-t-xl"
        />
        <div className="rounded-b-xl border-x border-b border-gray-200 px-4 pt-4 pb-6">
          <h3
            className={`mb-1 text-lg font-bold ${
              customButton ? `text-${customButton}-700` : `text-primary-700`
            }`}
          >
            {nftBlockData?.uriData?.name}
          </h3>
          <h4 className="mb-1 text-base font-medium text-gray-500">{title}</h4>
          <h5 className="mb-2 text-base font-medium text-gray-400">
            <span>Coleccionado por </span>
            <span className="text-gray-500">
              {nftBlockData.nftOwnerData &&
                (nftBlockData.nftOwnerData.username ? (
                  <a
                    target="_blank"
                    href={`/${nftBlockData.nftOwnerData.username}`}
                  >
                    {'@' + nftBlockData.nftOwnerData.username}
                  </a>
                ) : (
                  formatPublicKey(nftBlockData.nftOwnerData.address)
                ))}
            </span>
          </h5>
          <a
            href={`https://solscan.io/token/${address}`}
            target="_blank"
            className={`flex items-center transition-all duration-300 ${
              customButton
                ? `hover:text-${customButton}-700`
                : `hover:text-primary-700`
            }`}
          >
            <span
              className={`mr-[9px] underline decoration-1 underline-offset-1`}
            >
              Ver en Solscan
            </span>
            <ExternalLinkIcon className="h-5 w-5" />
          </a>
        </div>
      </div>
    );
  }

  return null;
};

export default SolanaNFTBlock;
