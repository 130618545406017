// @ts-nocheck
import React from 'react';
import dynamic from 'next/dynamic';

const TwitterPlayer = dynamic(() => import('../../../players/TwitterPlayer'), {
  ssr: false
});

interface ITwitterPlayerMediaProps {
  className?: string;
  url: string;
}

const TwitterPlayerMedia = ({
  className = '',
  url
}: ITwitterPlayerMediaProps) => {
  return (
    <div className={`flex justify-center rounded-md ${className}`}>
      <TwitterPlayer url={url} />
    </div>
  );
};

export default TwitterPlayerMedia;
