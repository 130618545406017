import { IUserSocialMedias } from 'types/User';
import SocialIcon from '../dashboard/settings/socialMedia/SocialIcon';

// Helpers
import { getFirstNCharacters } from 'helpers/formatData';
import { Mixpanel } from 'utils/mixpanel';

interface IUserSocialsLinksProps {
  className?: string;
  creatorUsername?: string;
  customColor?: string;
  hasCoverImage?: boolean;
  isPreviewMode?: boolean;
  social_medias: IUserSocialMedias;
}

const socialMediaSort = [
  'instagram',
  'twitter',
  'youtube',
  'twitch',
  'facebook',
  'opensea',
  'clubhouse',
  'spotify',
  'tiktok',
  'snapchat'
];

const UserSocialsLinks = ({
  className = '',
  creatorUsername,
  customColor,
  hasCoverImage,
  isPreviewMode,
  social_medias
}: IUserSocialsLinksProps) => {
  const isLink = (socialMedia: string) => {
    return getFirstNCharacters(socialMedia, 5) === 'https';
  };

  return (
    <div className={`flex flex-wrap justify-center gap-2.5 ${className}`}>
      {socialMediaSort.map((value, index) => {
        let theSocial: string = social_medias[value];

        if (theSocial === undefined || theSocial === '') return null;

        if (value === 'tiktok')
          theSocial.charAt(0) !== '@' && (theSocial = `@${theSocial}`);

        const link = isLink(theSocial)
          ? theSocial
          : `https://${value}.com/${theSocial}`;

        isPreviewMode === false &&
          Mixpanel.track('User clicks on social media link', {
            socialPlatform: value,
            url: link,
            creatorUsername
          });

        return (
          <a key={index} href={link} target="_blank">
            <SocialIcon
              icon={value}
              customColor={hasCoverImage ? 'white' : customColor}
            />
          </a>
        );
      })}
    </div>
  );
};

export default UserSocialsLinks;
