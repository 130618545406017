import { AxiosApi } from '../AxiosBase';
import { getCookie } from 'helpers/handleCookies';

export const AxiosWallets = async () => {
  const userToken = getCookie('token');

  try {
    if (!userToken) throw 'No estas logeado';

    const { data } = await AxiosApi.get('/subscriptions/wallets', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') throw data.message;

    return data.data;
  } catch (e: any) {
    console.log(e);
    console.log('AxiosWallets() Error');
    throw new Error(e.toString());
  }
};

export const AxiosGetSubscriptions = async () => {
  const userToken = getCookie('token');

  try {
    const result = await AxiosApi.get('/subscriptions/creators', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (result.status == 204) return null;

    return result.data;
  } catch (error) {
    console.error('AxiosGetSubscriptions() Error:', error);

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosGetSubscriptions: ${error}`);
  }
};

interface AxiosUserHaveSubscriptionResponse {
  message: string;
  haveSubscription: boolean;
}

export const AxiosUserHaveSubscription = async (
  creator_id: string
): Promise<AxiosUserHaveSubscriptionResponse> => {
  const userToken = getCookie('token');

  try {
    const { data } = await AxiosApi.get(
      `/subscriptions/userHaveSubscription/${creator_id}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') throw data.message;

    return data;
  } catch (error) {
    console.log(error);
    console.log('AxiosUserHaveSubscription() Error');
    throw new Error(error.toString());
  }
};
