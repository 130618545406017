import { tiktok_RegExp } from 'helpers/customRegExp';

interface ITikTokPlayer_V2Props {
  className?: string;
  url: string;
  size: 'xl' | 'lg';
}

const canPlay = (url: string) => tiktok_RegExp.test(url);

const TikTokPlayer_V2 = ({
  className = '',
  url,
  size
}: ITikTokPlayer_V2Props) => {
  if (!canPlay(url)) return null;

  let heightDiv: number = 0;
  let widthDiv: number = 0;
  let videoId: string = ""

  if (size === 'lg') {
    // sizes not official!
    widthDiv = 100;
    heightDiv = 180;
    //
  } else {
    widthDiv = 302;
    heightDiv = 562
  }

  if (url.startsWith("https://m.tiktok.com")) {
    const split = url.split(".html?");
    const split_2 = split[0].split("/");
    videoId = split_2[4]
  }

  else {
    const split = url.split("/");
    videoId = split[5]
  }

  return (
    <div
      className={`overflow-hidden rounded-md border border-white ${className}`}
      style={{ height: `${heightDiv}px`, width: `${widthDiv}px` }}
    >
      <iframe
        src={`https://www.tiktok.com/embed/${videoId}`}
        height={`${heightDiv}px`}
        allowFullScreen={true}
        scrolling={"no"}
      ></iframe>
    </div>
  );
};

TikTokPlayer_V2.canPlay = (url: string) => canPlay(url);

export default TikTokPlayer_V2;
