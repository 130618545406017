import { FC } from "react"

interface Props {
  type: "shopify_product" | "gloset_product"
  url: string,
  title: string,
  price: number,
  image: string,
  currency: string
}

const ShopifyProductBlock: FC<Props> = function({
  type,
  url,
  title,
  price,
  image,
  currency
}) {
  return (
    <a className={`flex p-4 rounded-lg ${type === "gloset_product" ? "bg-[#FF4C38]" : "bg-primary"}`} href={url} target="_blank">
      <div className="w-[60px] h-[60px] bg-gray-100 rounded-lg mr-4">
        <img
          className="w-full h-full object-cover object-center rounded-lg"
          src={image}
          alt="mis fans shopify"
        />
      </div>

      <div className="text-white">
        <h4 className="text-xl font-semibold leading-7">{title}</h4>
        <h5 className="text-lg font-medium leading-6">$ {price.toFixed(2)} {currency}</h5>
      </div>
    </a>
  )
}

export default ShopifyProductBlock