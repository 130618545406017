// @ts-nocheck
import React from 'react';
import ReactPlayer from 'react-player/youtube';

interface IYoutubePlayerMediaProps {
  className?: string;
  url: string;
}

const YoutubePlayerMedia = ({
  className = '',
  url
}: IYoutubePlayerMediaProps) => {
  if (!ReactPlayer.canPlay(url)) return null;

  return (
    <div className={`w-full overflow-hidden rounded-md ${className}`}>
      <ReactPlayer url={url} controls={true} width="100%" height="200px" />
    </div>
  );
};

export default YoutubePlayerMedia;
