// Libraries
import React from 'react';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { classNames } from 'helpers/classNames';

interface IUserInfoProps {
  className?: string;
  name: string;
  username: string;
  biography: string;
  isVerified?: boolean;
  customColor?: string;
  hasCoverImage?: boolean;
}

const UserInfo = ({
  className = '',
  name,
  username,
  biography,
  isVerified = false,
  customColor,
  hasCoverImage
}: IUserInfoProps) => {
  return (
    <div className={`text-center ${className} space-y-1`}>
      <h1
        className={classNames(
          hasCoverImage ? 'text-white' : 'text-gray-900',
          'flex items-center justify-center text-2xl font-semibold'
        )}
      >
        {name}
        {isVerified && (
          <BadgeCheckIcon
            className={classNames(
              hasCoverImage
                ? 'text-white'
                : customColor
                ? `text-${customColor}-500`
                : 'text-primary',
              'ml-1 h-6 w-6'
            )}
          />
        )}
      </h1>

      <h3
        className={classNames(
          hasCoverImage ? 'text-white' : 'text-gray-500',
          'text-base'
        )}
      >
        {biography}
      </h3>
    </div>
  );
};

export default UserInfo;
