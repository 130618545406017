import axios from 'axios';
import { classNames } from 'helpers/classNames';
import { Block } from 'types/accounts';
import { Mixpanel } from 'utils/mixpanel';

interface ILinkBlockProps {
  block: Block;
  className?: string;
  customColor?: string;
  isPreviewMode?: boolean;
  userId: string;
  username: string;
}

const DigitalProductBlock = ({
  block = undefined,
  className = '',
  customColor = undefined,
  isPreviewMode,
  userId = undefined,
  username = ''
}: ILinkBlockProps) => {
  const handleOnClick = async () => {
    try {
      const theWindow: any = window;

      isPreviewMode === false &&
        theWindow.plausible('Clicks', {
          props: {
            [userId.toString()]: block.title
          }
        });

      isPreviewMode === false &&
        (await axios({
          method: 'POST',
          url: 'api/user/analytics/block/click',
          data: {
            blockId: block.id,
            creator_id: userId
          }
        }));

      isPreviewMode === false &&
        Mixpanel.track('Click on Digital Product Block', {
          title: block.title,
          creatorUsername: username,
          url: block.url
        });
    } catch (e) {
      console.log(e);
      console.log('Plausible blocked by the user');
    } finally {
      window.location.assign(block.url);
    }
  };

  return (
    <div
      className={classNames(
        customColor
          ? `translate-all duration-300 ${
              customColor == 'ebonyclay'
                ? `bg-${customColor}-100 hover:bg-${customColor}-200`
                : customColor == 'eagle'
                ? `bg-${customColor}-100 hover:bg-${customColor}-200`
                : `bg-${customColor}-50 hover:bg-${customColor}-200`
            }`
          : 'bg-primary-50 hover:bg-primary-200',
        'mb-4 flex cursor-pointer items-center justify-between rounded-md px-4 py-4'
      )}
      onClick={handleOnClick}
    >
      <div className={'flex flex-row'}>
        <img
          className={'mr-4 h-[60px] w-[60px] rounded bg-gray-200 object-cover'}
          src={block.image}
        />
        <div className={'flex flex-col items-start text-base'}>
          <div
            className={classNames(
              `text-xl font-semibold leading-7 ${
                customColor ? `text-${customColor}-800` : `text-primary-800`
              }`
            )}
          >{`${block.title}`}</div>
          <div
            className={classNames(
              `text-lg font-medium leading-6 ${
                customColor ? `text-${customColor}-700` : `text-primary-700`
              }`
            )}
          >
            {block.price &&
              `$ ${Number(block.price).toFixed(2)} ${
                block.currency && block.currency.toUpperCase()
              }`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalProductBlock;
