import { AxiosApi } from '../AxiosBase';
import { getCookie } from 'helpers/handleCookies';

// Types
import { IUser } from 'types/User';

interface IAxiosNonceUserProfile {
  addr: string;
  user_id: number;
}

interface IAxiosVerifyNonce {
  message: string;
  addr: string;
  signature: string;
  user_id: number;
}

interface IAxiosUserFromAddress {
  address: string;
}

export const AxiosNonceUserProfile = async (
  payload: IAxiosNonceUserProfile
) => {
  const { addr, user_id } = payload;

  if (!addr) return { error: 'Falta tu wallet!' };
  else if (!user_id)
    return { error: 'Falta el id del usuario el cual conectaras tu wallet!' };

  const userToken = getCookie('token');

  try {
    const { data } = await AxiosApi.post('/phantom/nonce-profile', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: !userToken ? '' : `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosNonceUserProfile() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosVerifyNonce = async (payload: IAxiosVerifyNonce) => {
  try {
    const { data } = await AxiosApi.post('/phantom/nonce-verify', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (data.message !== 'OK') return { error: data.message };

    return { success: 'PRO' };
  } catch (e) {
    console.log(e);
    console.log('AxiosVerifyNonce() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosDisconnectWalletProfile = async (
  payload: IAxiosNonceUserProfile
) => {
  const userToken = getCookie('token');

  try {
    const { data } = await AxiosApi.post(
      '/phantom/disconnect-profile',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: !userToken ? '' : `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return { success: 'PRO' };
  } catch (e) {
    console.log(e);
    console.log('AxiosDisconnectWalletProfile() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosGetUserFromAddress = async (
  payload: IAxiosUserFromAddress
) => {
  try {
    const { data } = await AxiosApi.post(
      '/phantom/user-from-address',
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return { user: data.user as IUser };
  } catch (e) {
    console.log(e);
    console.log('AxiosGetUserFromAddress() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};
