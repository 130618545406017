import { AxiosApi } from '../../AxiosBase';

const AxiosGetImageBlurHash = async (url: string) => {
  try {
    const result = await AxiosApi.post(`/others/image-blurhash`, { url });

    return result.data;
  } catch (error) {
    console.error('AxiosGetImageBlurHash Error:', error);

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosGetImageBlurHash: ${error}`);
  }
};

export default AxiosGetImageBlurHash;
